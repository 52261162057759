import React from 'react';
import '../../../styles/ContactDetails.css'

function ContactDetails() {
    return (
        <div className='ContactDetails'>
            <div className="container">
                <div className="top-content">
                    <h2>CONTACT US</h2>
                    <p>Feel free to contact us with your queries.</p>
                </div>
                <div className="details">
                    <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0"><span><img src="img/contact-details/location.png" alt="location" /></span>Casero Technologies A7 / 2nd floor , Jaz tower, Makkolath Road, Palayam, Puthiyara (po), Kozhikode, Kerala , India , Pin-673004</p>
                    <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0"><span><img src="img/contact-details/mail.png" alt="mail" /></span><a href="caserotechnologies@gmail.com">caserotechnologies@gmail.com</a></p>
                    <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0"><span><img src="img/contact-details/phone.png" alt="phone" /></span><a href="tel:+91 8861942040">+91 8861942040</a></p>
                    <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0"><span><img src="img/contact-details/phone.png" alt="phone" /></span><a href="tel:+91 7994455144">+91 7994455144</a></p>

                </div>
            </div>
        </div>
    )
}

export default ContactDetails